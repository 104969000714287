.user-details-container {
  .badge{
    font-size: 0.8rem !important; 
    margin-left: -8px !important;
  }

    height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 2rem 0;
    max-width: 100%;
    overflow-x: hidden;
    background-color: #f8f9fa;

  .content-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    background-color: #f8f9fa;
  }

.loading-text {
margin-top: 1rem;
font-size: 1.2rem;
color: #555;
font-weight: 500;
}

  .card {
    margin-bottom: 20px;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    hr {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
  }

  .list-group-item {
    border: none;
    padding: 0.5rem 0;
  }




.mtable {
  .table {
  margin-bottom: 0;
  border-spacing: 0 0.5rem;
  border-collapse: separate;

  .th {
    background-color: #f8f9fa;
    font-weight: 600;
      font-weight: 600;
      color: #555;
      border-bottom: 1px solid #F1F3F5;
  }

  .tr {
      background-color: #ffffff;
    }

  .td {
      vertical-align: middle;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid #F1F3F5;
    }
}


}
}

h1{
  .kado-icon{
    height: 2rem;
  }
}

// Add this if you're not using Bootstrap or want to customize the muted style
.text-muted {
  color: #6c757d !important;
}

.recharts-wrapper {
  margin-bottom: 1rem;
}

.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid #ccc !important;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;

  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    line-height: 1.5;
  }
}

.chart-container {
  margin-bottom: 2rem;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.total-card {
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden; // This ensures the top border doesn't extend beyond the border-radius

  &:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .card-body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h4 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #495057;
  }

  .total-amount {
    font-size: 1.8rem;
    font-weight: bold;
    color: #212529;
    margin-bottom: 0.5rem;
  }

  .tx-count {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 0;
  }

  &.buy-card {
    border-top: 4px solid #3066DB;
  }

  &.sell-card {
    border-top: 4px solid #7757F9;
  }

  &.pay-card {
    border-top: 4px solid #00C49F;
  }
}

.chart-container {
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #495057;
  }
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  .label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #495057;
  }

  p {
    margin: 0;
    line-height: 1.5;
    color: #6c757d;
  }
}

.stats-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  

  h3 {
    font-size: 1.2rem;
    color: #495057;
    margin-bottom: 0.5rem;
  }

  .stats-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: #212529;
    margin-bottom: 0.25rem;
  }

  .stats-subvalue {
    font-size: 1rem;
    color: #6c757d;
  }
}

.chart-card {
  background-color: #ffffff;
  margin-top: 2rem;

  h5 {
    font-size: 1.2rem;
    color: #495057;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.transaction-history {
  margin-top: 2rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .table {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    th {
      background-color: #f8f9fa;
      border-bottom: 2px solid #dee2e6;
    }

    td, th {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }

    tbody tr:hover {
      background-color: #f8f9fa;
    }
  }
}

