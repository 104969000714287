.kado-url-builder {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
  }

  .description {
    margin-bottom: 2rem;
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
  }

  .card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    margin-bottom: 2rem;

    .card-body {
      padding: 1.5rem;
    }
  }

  .table {
    th, td {
      vertical-align: middle;
      padding: 0.75rem;
    }

    th {
      background-color: #f8f9fa;
      font-weight: 600;
      color: #495057;
    }

    td {
      font-size: 0.9rem;
    }
  }

  .form-control, .form-select {
    max-width: 300px;
    font-size: 0.9rem;
  }

  .btn {
    font-size: 0.9rem;
    padding: 0.375rem 0.75rem;
  }

  .generated-url-section {
    margin-top: 2rem;

    .card-title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      color: #333;
    }

    .settings-subtitle {
      font-size: 1rem;
      color: #666;
    }

    .qr-code-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      background-color: #ffffff;
      border-radius: 0.25rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-right: 1rem;

      p {
        margin-top: 0.5rem;
        font-size: 0.9rem;
        color: #666;
      }
    }

    .url-display {
      word-break: break-all;
      background-color: #f8f9fa;
      padding: 1rem;
      border-radius: 0.25rem;
      font-family: monospace;
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .kado-url-builder {
    padding: 15px;

    h1 {
      font-size: 1.75rem;
    }

    .table {
      font-size: 0.8rem;
    }

    .form-control, .form-select {
      max-width: 100%;
    }

    .generated-url-section {
      .qr-code-container {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

.kado-url-builder {
  .content-wrapper {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .page-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .description {
    margin-bottom: 20px;
  }

  .generated-url-section {
    margin-top: 20px;

    .url-display {
      word-break: break-all;
      background-color: #f8f9fa;
      padding: 10px;
      border-radius: 4px;
    }
  }

  .table {
    th, td {
      vertical-align: middle;
    }
  }
}