.orders-container {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 2rem 0;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f8f9fa;
}

.content-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1.5rem;
}

.filter-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.form-label {
  font-weight: 600;
  color: #555;
}

.stats-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  height: 100%;

}

.stats-card h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
  margin-bottom: 0.5rem;
}

.stats-value {
  font-size: 3rem;
  margin-bottom: 0;
  margin-top: 1.5rem;

}

.chart-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  height: auto;
  min-height: 450px;
  margin-bottom: 2rem;
}

.section-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
}

.orders-table-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
}

.table {
  margin-bottom: 0;
  border-spacing: 0 0.5rem;
  border-collapse: separate;
}

.table th {

  font-weight: 600;
  color: #555;
  border-bottom: 1px solid #F1F3F5;

}

.table td {
  vertical-align: middle;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #F1F3F5;
}

.table tr {
  background-color: #ffffff;
}

.table tr td:first-child {
  
}

.table tr td:last-child {
  
}

.status-badge {
  padding: 0.25em 0.6em;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 20px;
}

.status-badge-warning { background-color: #fff3cd; color: #856404; }
.status-badge-success { background-color: #d4edda; color: #155724; }
.status-badge-danger { background-color: #f8d7da; color: #721c24; }
.status-badge-secondary { background-color: #e9ecef; color: #6c757d; }

@media (max-width: 768px) {
  .chart-container {
    min-height: 350px;
  }
  
  .page-title {
    font-size: 2rem;
  }
  
  .stats-value {
    font-size: 1.5rem;
  }
}

/* Remove these styles as they're no longer needed */
/* .infinite-scroll-component {
  overflow: visible !important;
}

.infinite-scroll-component__outerdiv {
  overflow: visible !important;
} */

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  background-color: #f8f9fa;
}

.loading-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #555;
  font-weight: 500;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge.bg-primary {
  color: #0d6efd;
  background-color: rgba(13, 110, 253, 0.1);
}

.badge.bg-secondary {
  color: #6c757d;
  background-color: white !important
}

.badge.bg-success {
  color: #198754;
  background-color: white !important
}

.badge.bg-danger {
  color: #dc3545;
  background-color: white !important
}

.badge.bg-warning {
  color: #ffc107;
  background-color: white !important
}

.badge.bg-info {
  color: #0dcaf0;
  background-color: rgba(13, 202, 240, 0.1);
}

.badge.bg-light {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.1);
}

.badge.bg-dark {
  color: #212529;
  background-color: rgba(33, 37, 41, 0.1);
}

.empty-state {
  text-align: center;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-top: 2rem;
}

.empty-state-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}

.empty-state h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.empty-state p {
  color: #6c757d;
  margin-bottom: 1rem;
}

.tx-type {
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  
}

.tx-type.buy {
  
  color: #3066DB;
  
}

.tx-type.sell {
    
  color: #7757F9;
  
}

.tx-type svg {
  margin-right: 4px;
}

/* Add this style for the clickable ID */
.table td a {
  color: #007bff;
  text-decoration: none;
}


.payment-icon{
    width: 20px;
    display: inline-block;
    margin-right: 5px;
}