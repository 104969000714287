.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.signup-content {
  display: flex;
  max-width: 900px;
  width: 100%;
}

.signup-form-wrapper {
  flex: 1;
  max-width: 400px;
  margin-right: 2rem;
}

.logo-container {
  text-align: center;
  margin-bottom: 1rem;
}

.login-logo {
  width: 8rem;
  margin-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signup-form-wrapper h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
}

.form-label {
  font-weight: 600;
}

.btn-primary {
  background-color: #3066DB;
  border-color: #3066DB;
}

.signup-form a {
  color: #3066DB;
  text-decoration: none;
}

.signup-form a:hover {
  text-decoration: underline;
}

.signup-info {
  flex: 1;
  background-color: #f8f9fa;
  padding: 2rem;
  border-left: 1px solid #e9ecef;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.signup-info h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #333;
}

.signup-info ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  list-style: square;
}

.signup-info p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .signup-content {
    flex-direction: column;
  }

  .signup-form-wrapper {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .signup-info {
    border-left: none;
    border-top: 1px solid #e9ecef;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
  }
}

@media (max-width: 576px) {
  .signup-container {
    padding: 10px;
  }

  .card-body {
    padding: 1.5rem;
  }
  
  .signup-info ul {
    padding-left: 1rem;
  }
}