.settings-container {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 2rem 0;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f8f9fa;
}

.content-wrapper {
  margin: 0 auto;
  padding: 0 1rem;
}

.page-title {
  margin-bottom: 2rem;
  font-size: 2.2rem;
  font-weight: bold;

  .name {
    font-weight: normal;
    color: #666;
  }
}

.card {
  background-color: #ffffff;
  border: none !important;
  border-radius: 10px;
}

.settings-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  .card-title {
    font-size: 1.6rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
  }
}

.kado-pay-card {
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  .card-title {
    font-size: 1.6rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
  }
}

.help-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  .card-title {
    font-size: 1.6rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;

    li {
      margin-bottom: 0.5rem;

      a {
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.info-label {
  font-weight: 600;
  color: #555;
  margin-right: 0.5rem;
  min-width: 100px;
}

.info-value {
  flex-grow: 1;
  color: #333;
}

.copy-button, .edit-button, .save-button {
  padding: 0;
  color: #007bff;
  margin-left: 0.5rem;

  &:hover {
    color: #0056b3;
  }
}

.input-group {
  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-control {
    border-right: none;
  }
}

@media (max-width: 768px) {
  .page-title {
    font-size: 2rem;
  }
  
  .settings-card .card-title {
    font-size: 1.5rem;
  }

  .info-label {
    min-width: 80px;
  }
}

