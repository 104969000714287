.content-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .card {
    background-color: #ffffff;
    border: none !important;
    border-radius: 10px;
  }

  ul {
    li{
        &::marker {
            color: grey;
            
        } 
    }
}
.kado-icon {
    height: 1rem;
}
.badge{
  font-size: 0.8rem !important;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.table td a:hover {
  text-decoration: none;
}

.order-section hr, .timeline-section hr {
  margin-bottom: 1.5rem;
  margin-top: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

