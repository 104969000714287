.order-details-container {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 2rem 0;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f8f9fa;
}

.order-details-container .content-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}

.order-details-container h1 {
  margin-bottom: 2rem;
}

.order-details-container h2 {
  font-size: 1.2rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.order-details-container h2 svg {
  margin-right: 0.5rem;
  vertical-align: middle;
}

.order-details-container .card {
  margin-bottom: 20px;
}

.order-details-container .list-group-item {
  border-left: none;
  border-right: none;
}

.order-details-container .list-group-item:first-child {
  border-top: none;
}

.order-details-container .list-group-item:last-child {
  border-bottom: none;
}

.go-back-button {
  margin-bottom: 1rem;
}

.crypto-icon {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.order-details-container ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.order-details-container ul li {
  margin-bottom: 5px;
}

.timeline-item {
  position: relative;
}

.timeline-item:last-child {
  border-left: none;
}

.timeline-icon {
  position: absolute;
  left: -11px;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e9ecef;
}

.timeline-content {
  padding-left: 15px;
}

.timeline-content h3 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.timeline-content p {
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
}


.inline-badge {
  display: inline-block;
  margin-left: 5px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  background-color: #f8f9fa;
}

.loading-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #555;
  font-weight: 500;
}

.alert-danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.alert-danger .fa-exclamation-triangle {
  color: #721c24;
}

.alert-heading {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.alert-danger p {
  margin-bottom: 5px;
}

.tx-type {
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
}

.tx-type.buy {
  color: #3066DB;
}

.tx-type.sell {
  color: #7757F9;
}
.tx-type.pay {
  color: #007666
}

.tx-type svg {
  margin-right: 4px;
}

.payment-icon, .crypto-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  vertical-align: middle;
}

.timeline-card {
  position: sticky;
  top: 1rem;
}

@media (max-width: 767px) {
  .timeline-card {
    position: static;
  }
}

.order-section, .timeline-section {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
}

.order-section h2, .timeline-section h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}


.order-section h2 svg,
.timeline-section h2 svg {
  margin-right: 0.5rem;
  vertical-align: middle;
}

.order-section a {
  color: #007bff;
  text-decoration: none;
  word-wrap: break-word;
}

.order-section a:hover {
  text-decoration: underline;
}

.order-section a svg {
  margin-left: 4px;
  display: inline-block;
}

.inline-svg{
  display: inline-block;
  margin-right: 4px;;
}

.list-group-item{
  padding: 0px;
  margin: 0px important;
  padding-top: 4px;;
  padding-bottom: 4px;;
}

strong{
  margin-right: 6px;
}

.error-section {
  background-color: #f8d7da;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.error-section h2 {
  color: #721c24;
  margin-bottom: 0.5rem;
}

.error-section hr {
  border-top-color: #f5c6cb;
}

.error-section .alert {
  margin-bottom: 0;
}

.timeline {
  position: relative;
  padding: 0px 0;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  height: 100%;
  width: 1px;
  background: #eee;
}

.timeline-item {
  position: relative;
  margin-bottom: 10px;
}

.timeline-icon {
  position: absolute;
  top: 5px;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-content {
  margin-left: 40px;
  padding: 5px 10px 5px 15px;
  border-radius: 4px;
}

.timeline-date {
  font-size: 0.9em;
  color: #6c757d;
  margin-bottom: 5px;
}

.timeline-message {
  margin-bottom: 0;
}

h1 .crypto-icon {
  width: 2rem;
  height: 2rem;
}

.badge{
  font-size: 0.8rem !important; 
  margin-left: -2px !important;
}