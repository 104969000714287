.top-menu {
  background-color: #ffffff;
  padding: 10px 20px;
  position: relative;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
}

.menu-brand {
  color: #333;
  text-decoration: none;
  font-size: 1.4em;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
}

.menu-brand:hover {
  color: #007bff;
}

.menu-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.menu-items li {
  margin-left: 20px;
}

.menu-link {
  color: #555;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  font-size: 1em;
}

.menu-link:hover {
  background-color: #f8f9fa;
}

.menu-link.active {
  background-color: #007bff;
  color: white;
}

.logout-link {
  display: flex;
  align-items: center;
}

.logout-link svg {
  margin-right: 5px;
}

/* New styles for logout link */
.menu-link[title="Logout"]:hover {
  background-color: #ffebee; /* Light red background */
  color: #dc3545; /* Red text color */
}

.menu-link[title="Logout"]:hover svg {
  color: #dc3545; /* Red icon color */
}

@media screen and (max-width: 768px) {
  .menu-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-items {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .menu-items li {
    margin-left: 0;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}

.menu-logo {
  height: 30px; /* Adjust as needed */
  margin-right: 10px; /* Space between logo and text, if keeping the text */
  vertical-align: middle;
}

.environment-label {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7757F9;
  color: white;
  padding: 2px 10px;
  font-size: 8px;
  font-weight: bold;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1000;
}