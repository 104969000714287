.users-container {
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 2rem 0;
    max-width: 100%;
    overflow-x: hidden;
    background-color: #f8f9fa;

    .content-wrapper {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 1rem;
      }


      .loading-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 60px);
        background-color: #f8f9fa;
      }

.loading-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #555;
  font-weight: 500;
}

  .table {
    margin-bottom: 0;
    border-spacing: 0 0.5rem;
    border-collapse: separate;

    .th {
      background-color: #f8f9fa;
      font-weight: 600;
        font-weight: 600;
        color: #555;
        border-bottom: 1px solid #F1F3F5;
    }

    .tr {
        background-color: #ffffff;
      }

    .td {
        vertical-align: middle;
        padding: 0.75rem 1rem;
        border-bottom: 1px solid #F1F3F5;
      }

    td a:hover {
      text-decoration: none;
    }
  }
}
