/* Add this at the top of your SharedStyles.css file */
html {
  font-size: 14px; /* You can adjust this value as needed */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem; /* Corrected from 0.1rem to 1rem */
}

.page-container {
  padding: 2rem 0;
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

.content-wrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.card {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
}

.form-container {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 0 0.5rem;
  }
}

/* Add these styles to the existing SharedStyles.css */

.navbar {
  background-color: #f8f9fa;

}

.navbar-light .navbar-brand {
  color: #333;
}

.navbar-light .navbar-nav .nav-link {
  color: #555;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #007bff;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #007bff;
  font-weight: bold;
}

.btn-outline-primary {
  border-color: #007bff;
  color: #007bff;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: #007bff;
  color: #ffffff;
}
