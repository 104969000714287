.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.login-content {
  width: 100%;
  max-width: 400px;
}

.login-logo {
  width: 8rem;
  margin-bottom: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.login-form {
  background-color: #ffffff;
  border-radius: 8px;
}

.card {
  border: none;
}

.form-label {
  font-weight: 600;
}

.btn-primary {
  background-color: #3066DB;
  border-color: #3066DB;
}

.login-form a {
  color: #3066DB;
  text-decoration: none;
}

.login-form a:hover {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .login-container {
    padding: 10px;
  }

  .card-body {
    padding: 1.5rem;
  }
  
  ul{
    list-style: square !important;
    padding-left: 1rem;
  }
  
}