.dashboard-container {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 2rem 0;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #f8f9fa;
}

.content-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}

.page-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 1.5rem;
}

.filter-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.form-label {
  font-weight: 600;
  color: #555;
}

.chart-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  height: auto;
  min-height: 350px;
  margin-bottom: 2rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  background-color: #f8f9fa;
}

.loading-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #555;
  font-weight: 500;
}

@media (max-width: 768px) {
  .chart-container {
    min-height: 350px;
  }
  
  .page-title {
    font-size: 2rem;
  }
}

.empty-state {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;

}

.empty-state h2 {
  margin-bottom: 1rem;
  color: #333;
}

.empty-state p {
  margin-bottom: 1rem;
  color: #666;
}

.empty-state ol {
  text-align: left;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.empty-state li {
  margin-bottom: 0.5rem;
}

.empty-state a {
  color: #3066DB;
  text-decoration: none;
}

.empty-state a:hover {
  text-decoration: underline;
}